import { APP_CONFIG_SET_ACTION, APP_CONFIG_ERROR_ACTION } from 'componentlibrary';
import Config from '../Config/Config';

export function appConfigDefaultState() {
  return null;
}

export async function getAppConfig(dispatch) {
  let type = APP_CONFIG_SET_ACTION;
  let payload = {};
  try {
    const result = await fetch(`${Config.apiGateway.URL}/get-app-config`);
    payload = await result.json();
    sessionStorage.setItem('forwoodIdAppClientId', payload.forwoodid_app_client_id);
    payload = {
      maintenanceMode: payload.maintenance_mode,
      ssoEnabled: payload.sso_enabled,
      ssoMultiTenancy: payload.sso_multitenancy,
      disabledRoles: payload.disabled_roles,
      crmDacRolesList: payload.crm_dac_roles_list,
      safetyPlusDacRolesList: payload.safetyplus_dac_roles_list,
      enabledSupportButtons: payload.enabled_support_buttons,
      defaultRoles: payload.default_roles,
      assignmentRestrictedRoles: payload.assignment_restricted_roles,
      eulaEnabled: payload.eula_enabled,
      saveDialogueEnabled: payload.save_dialogue_enabled,
      managedUserEnabled: payload.managed_user_enabled,
      contractorCompanyDataSourceTeams:
        payload.contractor_company_data_source_teams,
      userFieldsDataMap: payload.user_fields_data_map,
      appVersion: payload.app_version,
      deploymentInProgress: payload.deployment_in_progress,
      enableSiteAdminBatchProcess: payload.site_admin_batch_process_enabled,
      customBrandingConfig: payload.custom_branding_config,
      jobRoleCategories: payload.job_role_categories,
      isEditMyProfileJobRoleCategory: payload.isedit_myprofile_jobrolecategory,
      logoUrl: payload.logo_url,
      homepageEnabled: payload.forwood_homepage_enabled,
      forwoodAllowedRedirctURLS: payload.forwood_allowed_redirect_urls,
      supportedCharacterSets: payload.supported_character_sets,
      forwoodIdAppClientId: payload.forwoodid_app_client_id,
      multitenantEnvironment: payload.multitenant_environment,
    };
    sessionStorage.setItem('forwoodIdAppClientId', payload.forwoodIdAppClientId);
  } catch (err) {
    type = APP_CONFIG_ERROR_ACTION;
    payload = true;
  }
  if (dispatch !== undefined && dispatch !== null
    && typeof dispatch === 'function'
  ) {
    dispatch({ type, payload });
  }
  return payload;
}

export default (state = appConfigDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case APP_CONFIG_SET_ACTION:
      return payload;

    case APP_CONFIG_ERROR_ACTION:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
};
