import 'core-js/stable';
import 'whatwg-fetch';
import './Sass/base.scss';

import React, {
  Suspense, lazy,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import config from './Config/Config';
import PropsRoute from './Containers/PropsRoute';
import i18n from './Translations/i18n';
import { getAppConfig } from './reducers/appConfig';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

const Logout = lazy(() => (import('./Components/Logout')));
const AppWithAuth = lazy(() => (import('./AppWithAuth')));

// Helper function to dynamically load the Intercom script
const loadIntercomScript = src => new Promise((resolve, reject) => {
  // Check if the script is already in the document
  if (document.querySelector(`script[src="${src}"]`)) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = src;

  script.onload = () => resolve();
  script.onerror = e => reject(new Error(`Failed to load Intercom script: ${e.message}`));

  document.head.appendChild(script);
});

// Check if Intercom is already initialized
const isIntercomLoaded = () => typeof window.Intercom === 'function';

// Function to initialize or update Intercom
const initializeOrUpdateIntercom = async (intercomSrc) => {
  if (!isIntercomLoaded()) {
    try {
      await loadIntercomScript(intercomSrc);
    } catch (error) {
      console.error('Error loading Intercom script:', error);
      return;
    }
  }

  const w = window;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    // If Intercom is already initialized, reattach and update settings
    ic('reattach_activator');
    if (w.intercomSettings) {
      ic('update', w.intercomSettings);
    }
  } else {
    // Queue up Intercom commands if not initialized
    const i = function (...args) { i.c(args); };
    i.q = [];
    i.c = function (args) { i.q.push(args); };
    w.Intercom = i;

    if (w.intercomSettings) {
      w.Intercom('boot', w.intercomSettings);
    }
  }
};

// Dynamically load the Intercom script and initialize it based on the environment
const initializeIntercom = async (envName) => {
  const intercomSrc = envName === 'prod'
    ? 'https://widget.intercom.io/widget/tlmqy4u1'
    : 'https://widget.intercom.io/widget/slathgp8';

  // Load and initialize Intercom
  await initializeOrUpdateIntercom(intercomSrc);
};

// Inject and initialize Intercom based on the environment stage
initializeIntercom(config.reactApp.ENV_NAME);

const fetchApiData = async () => {
  try {
    await getAppConfig(); // Replace with your API URL
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  // Fetch data on component mount
  useEffect(() => {
    const loadData = async () => {
      await fetchApiData();
      setLoading(false); // Set loading to false when the API call completes
    };
    loadData();
  }, []);
  // While loading, don't render anything
  if (loading) {
    return null; // You can also return a loading spinner here if needed
  }
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Suspense fallback={<React.Fragment />}>
            <AppWithAuth />
            <PropsRoute
              path="/logout"
              component={Logout}
            />
          </Suspense>
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};
ReactDOM.render(
  <AppWrapper />,
  document.getElementById('root'),
);

registerServiceWorker();
